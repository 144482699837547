<template>
  <div class="co-flex-row co-justify-end co-items-center co-w-full pa-2">
    <v-tooltip
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-avatar
          color="white"
          size="24"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            :color="state.color"
            dark
            size="16"
          >
            {{ state.icon }}
          </v-icon>
        </v-avatar>
      </template>
      <span>{{ state.hint }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        tips: {
          success: {
            icon: 'mdi-cloud-check-variant',
            color: 'success',
            hint: '上传成功'
          },
          error: {
            icon: 'mdi-cloud-alert',
            color: 'error',
            hint: '上传失败'
          },
          ready: {
            icon: 'mdi-cloud-upload',
            color: 'blue-grey',
            hint: '准备上传'
          }
        },
        state: {
          icon: 'mdi-cloud-upload',
          color: 'blue-grey',
          hint: '准备上传'
        }
      }
    },
    watch: {
      item: {
        handler (val) {
          const tip = this.tips[val.status] || this.tips.ready
          this.state = Object.assign({}, tip)
        },
        immediate: true
      }
    },
    methods: {}
  }
</script>
